import * as React from "react";

import { FormSpec, localized } from "../../../form-specs";
import { PageWidgetSpec, Widget } from "../../../../src/plugins/widget";
import { SortingPanel, SortingPanelWidget } from "./SortingPanel";

import { LocalizedContentBase } from "@maxxton/cms-api";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { Sort } from "../../mxts/searchfacet/searchFacet.enum";
import { WidgetGroup } from "../../widget.enum";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions {
    showPricing: boolean;
    showNames: boolean;
    showRatings: boolean;
    showPriority?: boolean;
    showDropdown: boolean;
    showLinks: boolean;
    showRadio: boolean;
    showSelect: string;
    showAsButton: boolean;
    buttonColor: ButtonColor;
    defaultSorting?: number;
    showArrivalDate: boolean;
    showDuration: boolean;
    enableDateAndTime: boolean;
    pricesToBeShown: NumberMultiSelectOption[];
    namesToBeShown: NumberMultiSelectOption[];
    ratingsToBeShown: NumberMultiSelectOption[];
    arrivalDatesToBeShown: NumberMultiSelectOption[];
    durationsToBeShown: NumberMultiSelectOption[];
    showDateAndTime: NumberMultiSelectOption[];
    sortReservationsByArrivalDate?: boolean;
    showCapacities: boolean;
    capacitiesToBeShown: NumberMultiSelectOption[];
    defaultSortLabel: localizedOptions[];
}
export enum SortingDisplayLayouts {
    LOW,
    HIGH,
}
interface localizedOptions extends LocalizedContentBase {
    defaultSortLabel: string;
}

// eslint-disable-next-line max-len
export type ButtonColor = "button--primary" | "button-inverse" | "button--secondary" | "button--ghost" | "button--brand-alternative";

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "search-sort-form",
    name: getI18nLocaleObject(namespaceList.widgetSearchSort, "searchSort"),
    pluralName: getI18nLocaleObject(namespaceList.widgetSearchSort, "searchSort"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                variable: "showPricing",
                                label: getI18nLocaleObject(namespaceList.widgetSearchSort, "showPrice"),
                                type: "checkbox",
                            },
                            {
                                variable: "pricesToBeShown",
                                type: "multiselect",
                                default: [
                                    { label: getI18nLocaleObject(namespaceList.widgetSearchSort, "lowToHighLabel"), value: SortingDisplayLayouts.LOW },
                                    { label: getI18nLocaleObject(namespaceList.widgetSearchSort, "highToLowLabel"), value: SortingDisplayLayouts.HIGH },
                                ],
                                label: getI18nLocaleObject(namespaceList.widgetSearchSort, "pricesToBeShown"),
                                optionList: () => [
                                    { text: getI18nLocaleObject(namespaceList.widgetSearchSort, "lowToHighLabel"), value: SortingDisplayLayouts.LOW },
                                    { text: getI18nLocaleObject(namespaceList.widgetSearchSort, "highToLowLabel"), value: SortingDisplayLayouts.HIGH },
                                ],
                                visible: (options: WidgetOptions) => options.showPricing,
                            },
                            {
                                variable: "showNames",
                                label: getI18nLocaleObject(namespaceList.widgetSearchSort, "showNames"),
                                type: "checkbox",
                            },
                            {
                                variable: "namesToBeShown",
                                type: "multiselect",
                                default: [
                                    { label: getI18nLocaleObject(namespaceList.widgetSearchSort, "labelAtoZ"), value: SortingDisplayLayouts.LOW },
                                    { label: getI18nLocaleObject(namespaceList.widgetSearchSort, "labelZtoA"), value: SortingDisplayLayouts.HIGH },
                                ],
                                label: getI18nLocaleObject(namespaceList.widgetSearchSort, "namesToBeShown"),
                                optionList: () => [
                                    { text: getI18nLocaleObject(namespaceList.widgetSearchSort, "labelAtoZ"), value: SortingDisplayLayouts.LOW },
                                    { text: getI18nLocaleObject(namespaceList.widgetSearchSort, "labelZtoA"), value: SortingDisplayLayouts.HIGH },
                                ],
                                visible: (options: WidgetOptions) => options.showNames,
                            },
                            {
                                variable: "showRatings",
                                label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "showRating"),
                                type: "checkbox",
                            },
                            {
                                variable: "ratingsToBeShown",
                                type: "multiselect",
                                default: [
                                    { label: getI18nLocaleObject(namespaceList.widgetSearchSort, "lowToHighLabel"), value: SortingDisplayLayouts.LOW },
                                    { label: getI18nLocaleObject(namespaceList.widgetSearchSort, "highToLowLabel"), value: SortingDisplayLayouts.HIGH },
                                ],
                                label: getI18nLocaleObject(namespaceList.widgetSearchSort, "ratingsToBeShown"),
                                optionList: () => [
                                    { text: getI18nLocaleObject(namespaceList.widgetSearchSort, "lowToHighLabel"), value: SortingDisplayLayouts.LOW },
                                    { text: getI18nLocaleObject(namespaceList.widgetSearchSort, "highToLowLabel"), value: SortingDisplayLayouts.HIGH },
                                ],
                                visible: (options: WidgetOptions) => options.showRatings,
                            },
                            {
                                variable: "showPriority",
                                label: getI18nLocaleObject(namespaceList.widgetSearchSort, "priority"),
                                type: "checkbox",
                            },
                            {
                                variable: "showArrivalDate",
                                label: getI18nLocaleObject(namespaceList.widgetSearchSort, "showArrivalDate"),
                                type: "checkbox",
                            },
                            {
                                variable: "arrivalDatesToBeShown",
                                type: "multiselect",
                                default: [
                                    { label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "ascendingArrivalDate"), value: SortingDisplayLayouts.LOW },
                                    { label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "descendingArrivalDate"), value: SortingDisplayLayouts.HIGH },
                                ],
                                label: getI18nLocaleObject(namespaceList.widgetSearchSort, "arrivalDatesToBeShown"),
                                optionList: () => [
                                    { text: getI18nLocaleObject(namespaceList.widgetSearchfacet, "ascendingArrivalDate"), value: SortingDisplayLayouts.LOW },
                                    { text: getI18nLocaleObject(namespaceList.widgetSearchfacet, "descendingArrivalDate"), value: SortingDisplayLayouts.HIGH },
                                ],
                                visible: (options: WidgetOptions) => options.showArrivalDate,
                            },
                            {
                                variable: "sortReservationsByArrivalDate",
                                label: getI18nLocaleObject(namespaceList.widgetSearchSort, "sortReservationsByArrivalDate"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => options.showArrivalDate,
                            },
                            {
                                variable: "showDuration",
                                label: getI18nLocaleObject(namespaceList.widgetSearchSort, "showDuration"),
                                type: "checkbox",
                            },
                            {
                                variable: "durationsToBeShown",
                                type: "multiselect",
                                default: [
                                    { label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "minToMaxDuration"), value: SortingDisplayLayouts.LOW },
                                    { label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "maxToMinDuration"), value: SortingDisplayLayouts.HIGH },
                                ],
                                label: getI18nLocaleObject(namespaceList.widgetSearchSort, "durationsToBeShown"),
                                optionList: () => [
                                    { text: getI18nLocaleObject(namespaceList.widgetSearchfacet, "minToMaxDuration"), value: SortingDisplayLayouts.LOW },
                                    { text: getI18nLocaleObject(namespaceList.widgetSearchfacet, "maxToMinDuration"), value: SortingDisplayLayouts.HIGH },
                                ],
                                visible: (options: WidgetOptions) => options.showDuration,
                            },
                            {
                                variable: "showCapacities",
                                label: getI18nLocaleObject(namespaceList.widgetSearchSort, "showCapacity"),
                                type: "checkbox",
                            },
                            {
                                variable: "capacitiesToBeShown",
                                type: "multiselect",
                                default: [
                                    { label: getI18nLocaleObject(namespaceList.widgetSearchSort, "lowToHighLabel"), value: SortingDisplayLayouts.LOW },
                                    { label: getI18nLocaleObject(namespaceList.widgetSearchSort, "highToLowLabel"), value: SortingDisplayLayouts.HIGH },
                                ],
                                label: getI18nLocaleObject(namespaceList.widgetSearchSort, "capacitiesToBeShown"),
                                optionList: () => [
                                    { text: getI18nLocaleObject(namespaceList.widgetSearchSort, "lowToHighLabel"), value: SortingDisplayLayouts.LOW },
                                    { text: getI18nLocaleObject(namespaceList.widgetSearchSort, "highToLowLabel"), value: SortingDisplayLayouts.HIGH },
                                ],
                                visible: (options: WidgetOptions) => options.showCapacities,
                            },
                            {
                                variable: "enableDateAndTime",
                                label: getI18nLocaleObject(namespaceList.widgetSearchSort, "enableDateAndTime"),
                                type: "checkbox",
                            },
                            {
                                variable: "showDateAndTime",
                                type: "multiselect",
                                default: [
                                    { label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "minToMaxDateAndTime"), value: SortingDisplayLayouts.LOW },
                                    { label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "maxToMinDateAndTime"), value: SortingDisplayLayouts.HIGH },
                                ],
                                label: getI18nLocaleObject(namespaceList.widgetSearchSort, "showDateAndTime"),
                                optionList: () => [
                                    { text: getI18nLocaleObject(namespaceList.widgetSearchfacet, "minToMaxDateAndTime"), value: SortingDisplayLayouts.LOW },
                                    { text: getI18nLocaleObject(namespaceList.widgetSearchfacet, "maxToMinDateAndTime"), value: SortingDisplayLayouts.HIGH },
                                ],
                                visible: (options: WidgetOptions) => options.enableDateAndTime,
                            },
                            {
                                variable: "showSelect",
                                label: getI18nLocaleObject(namespaceList.widgetSearchSort, "showSortOptions"),
                                type: "select",
                                default: "showDropdown",
                                optionList: [
                                    { value: "showLinks", label: getI18nLocaleObject(namespaceList.widgetSearchSort, "showLinks") },
                                    { value: "showDropdown", label: getI18nLocaleObject(namespaceList.widgetSearchSort, "showDropdown") },
                                    { value: "showRadio", label: getI18nLocaleObject(namespaceList.widgetSearchSort, "showRadio") },
                                ],
                            },
                            {
                                variable: "defaultSorting",
                                label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "defaultSort"),
                                type: "select",
                                optionList: [
                                    {
                                        value: Sort.defaultSorting,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "defaultSort"),
                                    },
                                    {
                                        value: Sort.lowToHighPrice,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "lowToHighPrice"),
                                    },
                                    {
                                        value: Sort.highToLowPrice,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "highToLowPrice"),
                                    },
                                    {
                                        value: Sort.ascendingName,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "ascendingName"),
                                    },
                                    {
                                        value: Sort.descendingName,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "descendingName"),
                                    },
                                    {
                                        value: Sort.highToLowRating,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "highToLowRating"),
                                    },
                                    {
                                        value: Sort.lowToHighRating,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "lowToHighRating"),
                                    },
                                    {
                                        value: Sort.priority,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "sortOnPriority"),
                                    },
                                    {
                                        value: Sort.ascendingArrivalDate,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "ascendingArrivalDate"),
                                    },
                                    {
                                        value: Sort.descendingArrivalDate,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "descendingArrivalDate"),
                                    },
                                    {
                                        value: Sort.maxToMinDuration,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "maxToMinDuration"),
                                    },
                                    {
                                        value: Sort.minToMaxDuration,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "minToMaxDuration"),
                                    },
                                    {
                                        value: Sort.lowToHighCapacity,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "lowToHighCapacity"),
                                    },
                                    {
                                        value: Sort.highToLowCapacity,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "highToLowCapacity"),
                                    },
                                    {
                                        value: Sort.minToMaxDateAndTime,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "minToMaxDateAndTime"),
                                    },
                                    {
                                        value: Sort.maxToMinDateAndTime,
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "maxToMinDateAndTime"),
                                    },
                                ],
                            },
                            localized({
                                variable: "defaultSortLabel",
                                tabContent: [
                                    {
                                        variable: "defaultSortLabel",
                                        label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "defaultSortLabel"),
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => options.defaultSorting !== null,
                            }),
                            {
                                variable: "showAsButton",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "displayAsButton"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.showSelect === "showDropdown",
                            },
                            {
                                variable: "buttonColor",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "color"),
                                type: "dual-color",
                                default: "button--primary",
                                visible: (options: WidgetOptions) => options.showAsButton,
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

export const sortingPanelWidget: PageWidgetSpec<WidgetOptions> = {
    id: "searchSortPanel",
    type: "page",
    widgetGroup: WidgetGroup ? WidgetGroup.DYNAMIC : 1,
    name: getI18nLocaleObject(namespaceList.widgetSearchSort, "searchSort"),
    description: getI18nLocaleObject(namespaceList.widgetSearchSort, "searchSortDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        showDropdown: true,
        showLinks: false,
        showNames: false,
        showPricing: true,
        showRadio: false,
        showRatings: false,
        showSelect: "showDropdown",
        showAsButton: false,
        buttonColor: "button--primary",
        showArrivalDate: false,
        showDuration: false,
        enableDateAndTime: false,
        pricesToBeShown: [],
        namesToBeShown: [],
        ratingsToBeShown: [],
        arrivalDatesToBeShown: [],
        durationsToBeShown: [],
        showDateAndTime: [],
        showCapacities: false,
        capacitiesToBeShown: [],
        defaultSortLabel: [],
    }),
    async render(widget: Widget<WidgetOptions>, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions) {
        return <SortingPanel options={widget.options} context={context} dynamicContainerOptions={dynamicContainerOptions} />;
    },
    async initDefaultFilter(widget, context, dispatcher) {
        await SortingPanelWidget.initDefaultFilter({
            context,
            options: widget.options,
            dispatchAction: dispatcher,
        });
    },
};
