import { FreeSearchSelectItem } from "../plugins/dynamic/free-search/dynamicFreeSearch.types";
import { NumberMultiSelectOption } from "../plugins/mxts/selectOption.types";
import { Sort } from "../plugins/mxts/searchfacet/searchFacet.enum";
import { SortingDisplayLayouts } from "../plugins/dynamic/sorting-panel";

export interface SortingParams {
    sortingOptionValues: NumberMultiSelectOption[];
    ascendingSortingValue: number;
    descendingSortingValue: number;
}

export const getSortingParameters = (sortingParameters: SortingParams) => {
    const { sortingOptionValues, ascendingSortingValue, descendingSortingValue } = sortingParameters;
    const sortingParamsArray = [];
    if (sortingOptionValues?.length) {
        sortingOptionValues.forEach((sortingOption: NumberMultiSelectOption) => {
            if (sortingOption.value === SortingDisplayLayouts.LOW) {
                sortingParamsArray.push(Sort[ascendingSortingValue]);
            }
            if (sortingOption.value === SortingDisplayLayouts.HIGH) {
                sortingParamsArray.push(Sort[descendingSortingValue]);
            }
        });
    } else {
        sortingParamsArray.push(Sort[ascendingSortingValue], Sort[descendingSortingValue]);
    }
    return sortingParamsArray;
};

export const sortByPriority = (freeSearchSelectItems: FreeSearchSelectItem[]) => {
    freeSearchSelectItems.sort((optA, optB) => {
        const optAPriority = optA.freeSearchData.priority;
        const optBPriority = optB.freeSearchData.priority;
        if (optAPriority && optBPriority) {
            return optAPriority - optBPriority;
        }
        return optA.value.localeCompare(optB.value); // If no priority, maintain the order
    });
};
