import * as React from "react";

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, NavLink } from "reactstrap";
import { MyEnvActionType, MyEnvReducerAction } from "../../../redux/actions/myEnvAction";
import { SortingParams, getSortingParameters } from "../../../utils/sortingPanel.util";
import { getHideWidgetClass, isClientLoggedIn } from "../../../components/utils";
import { getI18nLocaleString, wrapProps } from "../../../i18n";

import { ActionType } from "../../../redux/actions";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { Dispatch } from "redux";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { Sort } from "../../mxts/searchfacet/searchFacet.enum";
import { WidgetOptions } from "./";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import namespaceList from "../../../i18n/namespaceList";

interface SearchSortProps extends SearchSortDispatchProps, DynamicWidgetBaseProps<WidgetOptions>, SearchSortStoreProps {
    options: WidgetOptions;
    context: CMSProvidedProperties;
}

interface SearchSortState {
    sortingOption: string;
    disableWidget: boolean;
    dropdownOpen: boolean;
}

interface SearchSortDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction | MyEnvReducerAction>;
}

interface SearchSortStoreProps {
    dynamicFilter?: DynamicFilter;
}

export class SortingPanelWidget extends React.Component<SearchSortProps, SearchSortState> {
    public static async initDefaultFilter(props: SearchSortProps): Promise<void> {
        const defaultState = SortingPanelWidget.defaultState(props);
        SortingPanelWidget.updateFilter(defaultState.sortingOption, props);
    }

    private static defaultState(props: SearchSortProps): SearchSortState {
        return {
            sortingOption: props.options.defaultSorting
                ? (props.options.defaultSorting === Sort.lowToHighPrice && Sort[+props.options.defaultSorting]) || Sort[props.options.defaultSorting!]
                : Sort[Sort.highToLowRating],
            disableWidget: true,
            dropdownOpen: false,
        };
    }

    constructor(props: SearchSortProps) {
        super(props);
        this.state = {
            ...SortingPanelWidget.defaultState(props),
        };
    }

    public componentDidMount() {
        this.setState({ disableWidget: !isClientLoggedIn() });
        const { dispatchAction } = this.props;
        this.handleSortingDispatch(this.state.sortingOption);
        const action: MyEnvReducerAction = {
            type: MyEnvActionType.UPDATE,
            payload: {
                sortReservationsByArrivalDate: this.props.options?.sortReservationsByArrivalDate,
            },
        };
        dispatchAction(action);
    }

    public render(): JSX.Element | null {
        const {
            context: { currentLocale, site },
            options,
        } = this.props;
        const hideWidget = getHideWidgetClass(options, this.state.disableWidget);
        const localizedDefaultSortLabel: string | null = getLocalizedContent({ site, currentLocale, localizedContent: options.defaultSortLabel, keys: ["defaultSortLabel"] })?.defaultSortLabel || null;

        if (hideWidget === null) {
            return null;
        }

        const sortingValues: string[] = this.getSortingOptions(options);

        return (
            <div className={`sorting-panel ${hideWidget}`}>
                {options.showSelect === "showDropdown" && (
                    <Dropdown nav className={`sort-select ${options.showAsButton ? "sort-btn" : "sort-dropdown"}`} isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle nav className={"active"}>
                            {options.showAsButton ? (
                                <button
                                    // eslint-disable-next-line max-len
                                    className={`btn ${
                                        options.buttonColor && options.buttonColor.indexOf("theme") > -1
                                            ? `${options.buttonColor}-background`
                                            : options.buttonColor && options.buttonColor.indexOf("rgba") === -1
                                            ? options.buttonColor
                                            : ""
                                    }`}
                                    style={{ background: options.buttonColor && options.buttonColor.indexOf("rgba") > -1 ? options.buttonColor : undefined }}
                                >
                                    <span>{getI18nLocaleString(namespaceList.widgetSearchfacet, "sorting", currentLocale, site)}</span>
                                </button>
                            ) : (
                                <span className="sort-dropdown-select">
                                    {this.state.sortingOption === Sort[this.props.options.defaultSorting!]
                                        ? localizedDefaultSortLabel ||
                                          getI18nLocaleString(namespaceList.widgetSearchfacet, this.state.sortingOption, currentLocale, site) ||
                                          getI18nLocaleString(namespaceList.widgetSearchfacet, "defaultSort", currentLocale, site)
                                        : getI18nLocaleString(namespaceList.widgetSearchfacet, this.state.sortingOption, currentLocale, site)}
                                </span>
                            )}
                        </DropdownToggle>
                        <DropdownMenu>
                            {sortingValues.map((value: string, index: number) => (
                                <DropdownItem onClick={this.handleSortClick.bind(this, value)} className={this.state.sortingOption === value ? "active" : ""} key={index}>
                                    {Sort[this.props.options.defaultSorting!] === value
                                        ? localizedDefaultSortLabel ||
                                          getI18nLocaleString(namespaceList.widgetSearchfacet, value, currentLocale, site) ||
                                          getI18nLocaleString(namespaceList.widgetSearchfacet, "defaultSort", currentLocale, site)
                                        : getI18nLocaleString(namespaceList.widgetSearchfacet, value, currentLocale, site)}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                )}
                {options.showSelect === "showLinks" && (
                    <div className="sort-links">
                        {sortingValues.map((value, index) => (
                            // eslint-disable-next-line max-len
                            <NavLink key={index} style={{ display: "inline-block" }} active={this.state.sortingOption === value} onClick={this.handleAnchorSortClick.bind(this, value)}>
                                {getI18nLocaleString(namespaceList.widgetSearchfacet, value, currentLocale, site)}
                            </NavLink>
                        ))}
                    </div>
                )}
                {options.showSelect === "showRadio" && (
                    <div className="sort-radio">
                        {sortingValues.map((value, index) => (
                            <div key={index} style={{ display: "inline-block" }} className="space-mr-s">
                                <label htmlFor={`radio-${index}`} className="mr-2">
                                    <Input type="radio" value={value} onChange={this.handleSortClick.bind(this, value)} key={`${index}-radio`} checked={this.state.sortingOption === value} />
                                    <div className="sort-radio__name"> {` ${getI18nLocaleString(namespaceList.widgetSearchfacet, value, currentLocale, site)}`}</div>
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    private getSortingOptions = (options: WidgetOptions) => {
        const sortingParams: string[] = [];
        const {
            showRatings,
            showPricing,
            showNames,
            showPriority,
            showArrivalDate,
            showDuration,
            ratingsToBeShown,
            pricesToBeShown,
            namesToBeShown,
            arrivalDatesToBeShown,
            durationsToBeShown,
            enableDateAndTime,
            showDateAndTime,
            showCapacities,
            capacitiesToBeShown,
        } = options;
        let sortingParameters: SortingParams;

        if (showRatings) {
            sortingParameters = {
                sortingOptionValues: ratingsToBeShown,
                ascendingSortingValue: Sort.lowToHighRating,
                descendingSortingValue: Sort.highToLowRating,
            };
            sortingParams.push(...getSortingParameters(sortingParameters));
        }
        if (showPricing) {
            sortingParameters = {
                sortingOptionValues: pricesToBeShown,
                ascendingSortingValue: Sort.lowToHighPrice,
                descendingSortingValue: Sort.highToLowPrice,
            };
            sortingParams.push(...getSortingParameters(sortingParameters));
        }
        if (showNames) {
            sortingParameters = {
                sortingOptionValues: namesToBeShown,
                ascendingSortingValue: Sort.ascendingName,
                descendingSortingValue: Sort.descendingName,
            };
            sortingParams.push(...getSortingParameters(sortingParameters));
        }
        if (showArrivalDate) {
            sortingParameters = {
                sortingOptionValues: arrivalDatesToBeShown,
                ascendingSortingValue: Sort.ascendingArrivalDate,
                descendingSortingValue: Sort.descendingArrivalDate,
            };
            sortingParams.push(...getSortingParameters(sortingParameters));
        }
        if (showDuration) {
            sortingParameters = {
                sortingOptionValues: durationsToBeShown,
                ascendingSortingValue: Sort.minToMaxDuration,
                descendingSortingValue: Sort.maxToMinDuration,
            };
            sortingParams.push(...getSortingParameters(sortingParameters));
        }
        if (showCapacities) {
            sortingParameters = {
                sortingOptionValues: capacitiesToBeShown,
                ascendingSortingValue: Sort.lowToHighCapacity,
                descendingSortingValue: Sort.highToLowCapacity,
            };
            sortingParams.push(...getSortingParameters(sortingParameters));
        }
        if (enableDateAndTime) {
            sortingParameters = {
                sortingOptionValues: showDateAndTime,
                ascendingSortingValue: Sort.minToMaxDateAndTime,
                descendingSortingValue: Sort.maxToMinDateAndTime,
            };
            sortingParams.push(...getSortingParameters(sortingParameters));
        }
        if (showPriority) {
            sortingParams.push(Sort[Sort.priority]);
        }
        return sortingParams;
    };

    private handleSortClick = (value: string, event: React.ChangeEvent<HTMLInputElement>) => {
        const sortValue = value ? value : event.target.value;
        this.handleSortingDispatch(sortValue);
    };

    private handleAnchorSortClick = async (option: string) => {
        this.handleSortingDispatch(option);
    };

    private static updateFilter(name: string, props: SearchSortProps) {
        if (SortingPanelWidget.getSort(name)) {
            const dynamicFilter = props.context.reduxStore?.store.getState().dynamicFilter;
            if (dynamicFilter?.sortingOption === name) {
                return;
            }

            const action: FilterChangeAction = {
                type: ActionType.FilterChange,
                filter: dynamicFilterType.sorting,
                payload: {
                    sortingOption: name,
                },
            };
            props.dispatchAction(action);
        }
    }

    private handleSortingDispatch(name: string) {
        for (const sort in Sort) {
            if (sort === name) {
                this.setState({ sortingOption: name }, () => {
                    SortingPanelWidget.updateFilter(name, this.props);
                });
                break;
            }
        }
    }

    private static getSort(name: string): string | undefined {
        for (const sort in Sort) {
            if (sort === name) {
                return sort;
            }
        }
    }

    private toggle = () => {
        this.setState((state) => ({
            dropdownOpen: !state.dropdownOpen,
        }));
    };
}

const mapDispatchToProps = (dispatch: Dispatch<FilterChangeAction | MyEnvReducerAction>): SearchSortDispatchProps => ({ dispatchAction: dispatch });

// eslint-disable-next-line max-len
const SortingPanelConnected = connect<SearchSortStoreProps, SearchSortDispatchProps>(null, mapDispatchToProps)(SortingPanelWidget);
export const SortingPanel = wrapProps<DynamicWidgetBaseProps<WidgetOptions>>(SortingPanelConnected);
